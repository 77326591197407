<template>
  <div class="product">
    <div v-if="!loading" style="padding-bottom: 50px;">
      <div class="columns">
        <div class="column">
          <button class="button" @click="$router.go(-1)">Back</button>
        </div>
        <div class="column buttons" style="display: flex; justify-content: flex-end;">
          <button @click="save" class="button is-primary">
            <span class="icon">
              <i class="fas fa-save" aria-hidden="true"></i>
            </span>
            <span>Save</span>
          </button>
          <div class="dropdown is-hoverable is-right">
            <div class="dropdown-trigger">
              <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                <span>Options</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a href="#" class="dropdown-item">
                  Adjust Price
                </a>
                <a @click="auditModalActive = true" href="#" class="dropdown-item">
                  Audit
                </a>
                <router-link :to="'/dashboard/listings/' + uid + '/variations'" class="dropdown-item">
                  Variation Builder
                </router-link>
                <a href="#" class="dropdown-item">
                  Mark as Out of Stock
                </a>
                <a :href="'https://moscadesign.com/product/' + product.slug" target="_blank" class="dropdown-item">
                  View on Website
                </a>
                <hr class="dropdown-divider">
                <a href="#" class="dropdown-item">
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-quarter">
          <figure class="has-shadow image is-square">
            <img :src="product.images && product.images[0]? product.images[0] : ''"/>
          </figure>
        </div>
        <div class="column">
          <div class="field">
            <label>Title</label>
            <input type="text" class="input" v-model="product.name">
          </div>
          <div class="field">
            <label>SKU</label>
            <input type="text" class="input" v-model="product.sku">
          </div>

          <div class="columns" v-if="product.type == 'simple' || product.type == 'variation'">
            <div class="column">
              <div class="field">
                <label>Regular Price</label>
                <input type="text" class="input" v-model="product.regular_price">
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label>Sale Price</label>
                <input type="text" class="input" v-model="product.sale_price">
              </div>
            </div>
          </div>

          <div class="field">
            <label>Description</label>

            <textarea class="textarea" v-model="product.description"></textarea>
            <button class="button is-small" @click="clearFormatting">Clear Bold, Italic</button>

          </div>

          <div v-if="product.type == 'variation' && product.attributes && product.attributes.length > 0">
            <h2>Attributes</h2>
            <ul>
              <li v-for="attribute in product.attributes">
                {{attribute.name}}: {{attribute.option}}
              </li>
            </ul>
          </div>
        </div>
        
      </div>
      <hr>

     

      <div class="variations" v-if="hasVariations">
        <h2 class="is-size-5">Variations</h2>
        
        <table class="table is-fullwidth">
          <thead>
            <th></th>
            <th>SKU</th>
            <th v-for="attribute in variationAttributes" >
              {{attribute.name}}
            </th>
            <th>Regular Price</th>
            <th>Sale Price <button @click="applyDiscount" class="button is-small">-15%</button><button @click="clearDiscount" class="button is-small">Clear</button></th>
          </thead>
          <tbody>
            <tr v-for="(variation, i) in variations" :key="variation.id">
              <td><small>#{{variations[i].id}}</small></td>
              <td>
                <input type="text" class="input" v-model="variations[i].sku">              
              </td>
              <td v-for="attribute in variations[i].attributes" :key="attribute.name">
                {{attribute.option}}
              </td>
              <td>
                <input type="text" class="input" v-model="variations[i].regular_price">              
              </td>
              <td>
                <input type="text" class="input" v-model="variations[i].sale_price">              
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <AuditProductModal @close="auditModalActive = false" :active="auditModalActive" :product="product"></AuditProductModal>
    </div>
    <div v-else style="position: relative; width: 100%; height: 100%;">
      <Loader is-active="true"></Loader>
    </div>
  </div>
</template>

<script>
import Products from "@/api/ProductListings"
import Loader from "@/components/global/Loader"
import Price from "@/components/dashboard/productListings/Price"
import AuditProductModal from "@/components/dashboard/productListings/AuditProductModal"
import {mapGetters} from 'vuex'

export default {
  name: 'Product',
  components: {
    Loader,
    Price,
    AuditProductModal
  },
  data() {
    return {
      product: {},
      loading: true,
      auditModalActive: false,
      error: false,
      modalActive: false,
      modalProduct: {},
      variations: []
    }
  },
  methods: {
    clearFormatting() {
      var bannedTags = 
          [
            "strong", "i", "b"
          ];
        
      var el = document.createElement('div');
      el.innerHTML = this.product.description;

      bannedTags.forEach(tag => {
        el.querySelectorAll(tag).forEach((item, index) =>  {
          item.outerHTML = item.innerHTML
 
        })
      })
      
      this.product.description = el.innerHTML
    },

    clearDiscount() {
      if(this.product.type == "variable") {
        console.log("Clearing discount")
        for(var i = 0; i < this.variations.length; i++) {
          console.log("updated one")
          this.variations[i].sale_price = ""
        }
      }
    },
    applyDiscount() {
      if(this.product.type == "variable") {
        console.log("Applying 15% discount")
        for(var i = 0; i < this.variations.length; i++) {
          console.log("updated one")
          this.variations[i].sale_price = (this.variations[i].regular_price * .85).toFixed(2)
        }
      }
    },
    openModal(product) {
      this.modalProduct = product
      this.modalActive = true
    },
    setProduct(product) {
      console.log("SET PRODUCT", product)
      this.product = product

      if(product.variations.length == 0) {
        this.loading = false
        return
      }

      var operations = []
      var variations = product.variations

      variations.forEach(variation => {
          //This should get and upload the variation as well as replace the id with the UID
          operations.push(Products.getVariation(variation))
      })

      Promise.all(operations).then(this.processVariationResponses).catch(this.throwError)
      
    },
    save() {
      var product = this.product
      this.loading = true
      if(product.type == "simple") {
        var update = {
          id: product.id,
          sku: product.sku,
          regular_price: product.regular_price,
          description: product.description,
          sale_price: product.sale_price,
          name: product.name,
          uid: product.uid,
         // categories: this.categoryIDs
        }
        // Just save product
        Products.updateProduct({update: update}).then(this.goBack).catch((error) => {
          console.log("ERROR", error)
        })

      } else if(product.type == "variable") {
        var product = this.product
        //product.categories = this.categoryIDs
        //Save product and variations

        for(var i = 0; i < this.variations.length; i++) {
          if(Number(this.variations[i].sale_price) <= 0) {
            this.variations[i].sale_price = ""
          }
        }

        Products.updateProductWithVariations(this.product, this.variations).then(this.goBack).catch(error => {
          console.log("ERROR", error)
        })
      }
    },
    goBack(data) {
      if(data) console.log(data)
      this.$router.go(-1)
      
    },
    processVariationResponses(responses) {
      this.variations = responses
      this.variations.forEach(variation => {
        if(variation.sku == this.product.sku) {
          variation.sku = ""
        }

        if(variation.sale_price.length == 0 || variation.sale_price == 0) {
          variation.sale_price = "";
        }
      })
      this.loading = false
    },
    throwError(error) {
      console.log("Error loading product variations", error)
      this.error = true
    }
  },
  computed: {
    uid: function() {
      return this.$route.params.uid
    },
    hasVariations: function() {
      return this.variations.length > 0 || (this.product.variations && this.product.variations > 0)
    },
    variationAttributes: function() {
      return this.product.attributes.filter(attribute => attribute.variation == true)
    },
    ...mapGetters({
      productCategories: "ProductListingCategories/getProductCategories"
    }),
    categoryIDs() {
      var ids = []
      this.product.categories.forEach(cat => {
        ids.push({id: cat})
      })

      return ids
    },
    categories() {
      return this.productCategories.sort(function(a, b){
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
      })
    }
  },
  mounted: function() {
    Products.getByUID(this.uid).then(this.setProduct)
  }
}
</script>
<style scoped>
  .product {
    height: 100%;
  }

  .is-square img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>