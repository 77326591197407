<template>
  <div class="productCategory">
    <div v-if="productCategory != undefined && products">
      <div class="columns">
        <div class="column" style="display: flex; align-items: flex-end; justify-content: flex-start;">
          <h1 class="title" v-html="productCategory.name"></h1>
        </div>
        <div class="column" style="display: flex; align-items: flex-end; justify-content: flex-end;">
          <div class="field" style="max-width: 150px;">
            <label>View Mode</label>
            <select class="input" v-model="viewType">
              <option value="grid">Grid</option>
              <option value="table">Table</option>
            </select>
          </div>
        </div>
      </div>
  
      <div class="columns is-multiline" style="width: 100%;" v-if="viewType == 'grid'">
        <div class="column is-one-fifth" v-for="product in products">
          <ProductCard :uid="product.uid" :fetch="false" :product="product"></ProductCard>
        </div>
      </div>

      <div class="productTable" v-if="viewType == 'table'">
        <div class="columns">
          <div class="column">
            <div class="dropdown is-hoverable" v-if="selected.length > 0">
              <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                  <span> {{selected.length}} {{selected.length == 1? 'product' : 'products'}} selected</span>
                  <span class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <div href="#" class="dropdown-item">
                    <small>Add to Product List</small>
                  </div>
                  <a @click="addToList(list.uid)" class="dropdown-item" v-for="list in productLists">
                    {{list.title}}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
          </div>
        </div>
        <table class="table is-fullwidth">
          <thead>
            <th></th>
            <th></th>
            <th></th>
            <th></th>

          </thead>
          <tbody>
            <tr v-for="product in products" :key="product.uid">
              <td>
                <input :id="product.uid" :value="product" v-model="selected" type="checkbox" class="checkbox"/>
              </td>
              <td>
                <figure class="image is-square">
                  <img :src="product.images[0]"/>
                </figure>
              </td>
              <td>
                <router-link :to="'/dashboard/listings/' + product.uid">{{product.name}}</router-link>
              </td>
              <td>
                <Price :regular_price="product.regular_price" :sale_price="product.sale_price" v-if="product.type == 'simple'"></Price>
                <span style="display: flex; align-items: center;" v-else><small style="color: #666; margin-right: 5px;">Starting at</small> <Price :regular_price="product.price + ''" :sale_price="0"></Price></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      

      <div class="pagination">
        <button class="button" v-if="page > 1" @click="previousPage">Back</button>
        <button class="button" @click="nextPage" v-if="products.length >= pageSize">Next</button>  
      </div>

    </div>
    <div v-else-if="error & loaded">
      <h1 class="title">Failed to Load Product Category</h1>
      <small>{{errorMessage}}</small>
    </div>
    <div class="loader_container" v-else>
      <div>
        <Loader is-active="true"></Loader>
      </div>
    </div>

  
  </div>
</template>

<script>
import ProductCard from "@/components/dashboard/productListings/ProductCard"
import Loader from "@/components/global/Loader"
import Products from "@/api/ProductListings"
import {mapGetters} from 'vuex'
import Price from "@/components/dashboard/productListings/Price"
import $ from 'jquery'

export default {
  name: 'Product Category',
  components: {
    Loader,
    ProductCard,
    Price
  },
  data() {
    return {
      subCategories: [],
      categoryTitle: "",
      categoryID: "",
      loaded: false,
      error: false,
      errorMessage: "",
      page: 1,
      pageSize: 25,
      viewType: "table",
      selected: []
    }
  },
  methods: {
    addToList(uid) {
      this.$store.dispatch("ProductLists/addToList", {uid: uid, products: this.selected})
    },
    changePage(page) {
      this.page = page
      this.$store.dispatch("ProductListings/setProductsByCategory", {id: this.$route.params.id, page: this.page})
    },
    nextPage() {
      this.page++
      this.$store.dispatch("ProductListings/nextPage", {id: this.$route.params.id, page: this.page})
    },
    previousPage() {
      this.page--
      this.$store.dispatch("ProductListings/previousPage", {id: this.$route.params.id, page: this.page})
    }
  },
  computed: {
    categoryID: function() {
      return this.$route.params.id
    },
    ...mapGetters({
      products: "ProductListings/getListings",
      productCategory: "ProductCategory/getProductCategory",
      productLists: "ProductLists/getLists"
    }),

  },
  watch: {
    '$route'() {
      // TODO: react to navigation event.
      // params cotains the current route parameters
      var routeID = Number(this.$route.params.id)
      this.$store.dispatch("ProductCategory/setProductCategory", routeID)
      this.$store.dispatch("ProductListings/setListingsByCategory", {id: routeID, page: this.page})
      this.$store.dispatch("ProductLists/setLists")

    }
  },
  mounted: function() {
    var route = this.$route
    var routeID = Number(this.$route.params.id)

    this.$store.dispatch("ProductCategory/setProductCategory",  this.$route.params.id)
    this.$store.dispatch("ProductListings/setListingsByCategory", {id: this.$route.params.id, page: this.page})
      this.$store.dispatch("ProductLists/setLists")

    console.log(this.$route)
  }
}
</script>
<style scoped>

  img {
    object-fit: contain;
  }

  .loader_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader_container div {
    min-width: 500px;
    min-height: 500px;
  }

</style>
