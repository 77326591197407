<template>
  <div class="newPromotionModal">
    <div :class="'modal'  +  (isActive? ' is-active':'')">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">New Promotion</p>
          <button class="delete" aria-label="close" @click="onClose"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Name</label>
                <input v-model="name" type="text" class="input">
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Discount</label>
                <input v-model="discount" type="text" class="input">
              </div>
            </div>
          </div>

          <div class="block">
            <label class="label">Include Products</label>

            <div class="columns">
              <div class="column">
                <div class="select">
                  <select v-model="currentIncludeField">
                    <option :value="field" v-for="field in fields">{{field}}</option>
                  </select>
                </div>
              </div>
              <div class="column">
                <div class="select">
                  <select v-model="currentIncludeCondition">
                    <option :value="condition" v-for="condition in conditions">{{condition}}</option>
                  </select>
                </div>
              </div>

              <div class="column">
                <div class="select" v-if="currentIncludeField == 'Category'">
                  <select v-model="currentIncludeValue">
                    <option :value="category.id + ',' + category.children.join(',')" v-for="category in categories" v-html="category.name"></option>
                  </select>
                </div>
                <div class="select" v-if="currentIncludeField == 'Supplier'">
                  <select v-model="currentIncludeValue">
                    <option v-for="supplier in suppliers" v-html="supplier.name"></option>
                  </select>
                </div>
            </div>
          </div>
            
          </div>
          <div class="block">
            <label class="label">Exclude Products</label>

              <div class="columns">
                <div class="column">
                  <div class="select">
                    <select v-model="currentExcludeField">
                      <option :value="field" v-for="field in fields">{{field}}</option>
                    </select>
                  </div>
                </div>
                <div class="column">
                  <div class="select">
                    <select v-model="currentExcludeCondition">
                      <option :value="condition" v-for="condition in conditions">{{condition}}</option>
                    </select>
                  </div>
                </div>

                <div class="column">
                  <div class="select" v-if="currentExcludeField == 'Category'">
                    <select v-model="currentExcludeValue">
                      <option :value="category.id + ',' + category.children.join(',')" v-for="category in categories" v-html="category.name"></option>
                    </select>
                  </div>
                  <div class="select" v-if="currentExcludeField == 'Supplier'">
                    <select v-model="currentExcludeValue">
                      <option v-for="supplier in suppliers" v-html="supplier.name"></option>
                    </select>
                  </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button :disabled="canCreate" @click="createPromotion" class="button is-success">Create</button>
          <button class="button" @click="onClose">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'New Promotion Modal',
  data() {
    return {
      name: "",
      discount: "",
      currentIncludeField: "Supplier",
      currentIncludeCondition: "",
      currentIncludeValue: "",
      currentExcludeField: "Supplier",
      currentExcludeCondition: "",
      currentExcludeValue: "",
      include: [],
      exclude: [],
      fields: [
        "Supplier",
        "Category",
      ],
      conditions: [
        "Equals",
        "Does Not Equal"
      ]
    }
  },
  props: {
    isActive: Boolean
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    addInclude() {
      var data = {
        field: this.currentIncludeField,
        condition: this.currentIncludeCondition == 'Equals'? '==' : '!=',
        value: this.currentIncludeValue
      }

      if(data.field == "Category") {
        var arr = data.value.split(",")
        for(var i = 0; i < arr.length; i++) {
          arr[i] = Number(arr[i])
        }
        data.value = arr
      }

      this.include.push(data)
    },
    addExclude() {
      this.exclude.push({
        field: this.currentExcludeField,
        condition: this.currentExcludeCondition == 'Equals'? '==' : '!=',
        value: this.currentExcludeValue
      })
    },
    createPromotion() {
      this.addInclude()
      this.addExclude()
      this.$store.dispatch("Promotions/createPromotion", {
        name: this.name,
        discount: Number(this.discount),
        include: this.include,
        exclude: this.exclude,
        status: "Waiting For Processing",
        product_count: -1
      }).then(this.onClose)
    }
  },
  computed: {
    canCreate() {
      return !(this.name.length > 0 && this.currentIncludeField.length > 0 && this.currentIncludeCondition.length > 0 && (this.currentIncludeValue + "").length > 0)
    },
    ...mapGetters({
      suppliers: "ProductSuppliers/getProductSuppliers",
      categories: "ProductListingCategories/getProductCategories"
    })
  },
  mounted: function() {
    this.$store.dispatch("ProductSuppliers/setProductSuppliers")
  }
}
</script>
<style scoped>
  th {
    width: 33.3333%;
  }
  select, .select {
    width: 100%;
  }
</style>