<template>
  <div class="productUpdates">
    <NewProductUpdateModal @close="closeModal" :active="newModalOpen"></NewProductUpdateModal>
    <ProductUpdateModal :update="targetUpdate" :active="viewModalOpen" @close="closeModal"></ProductUpdateModal>
    <div class="columns">
      <div class="column">
        <h1 class="title">Product Updates</h1>
      </div>
      <div class="column" style="display: flex; justify-content: flex-end;">
        <button @click="modalOpen = true" class="button">Create New</button>
      </div>
    </div>
    <table class="table is-fullwidth">
      <thead>
        <th>SKU</th>
        <th>Description</th>
        <th>Status</th>
      </thead>
      <tbody>
        <tr @click="viewUpdate(update)" v-for="update in updates">
          <td>
            {{update.sku}}
          </td>
           <td>
            {{getShortDescription(update.description)}}
          </td>
          <td>
            <div class="tag is-light is-primary">
              {{update.status}}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import NewProductUpdateModal from "@/components/dashboard/productListings/NewProductUpdateModal"
import ProductUpdateModal from "@/components/dashboard/productListings/ProductUpdateModal"

export default {
  name: 'Product Updates',
  data() {
    return {
      newModalOpen: false,
      viewModalOpen: false,
      targetUpdate: undefined
    }
  },
  methods: {
    viewUpdate(update) {
      this.targetUpdate = update
      this.viewModalOpen = true
    },
    closeModal() {
      this.newModalOpen = false
      this.viewModalOpen = false
    },
    getShortDescription(description) {
      return description.length > 185? description.substr(0, 182) + "..." : description
    }
  },
  components: {
    NewProductUpdateModal,
    ProductUpdateModal
  },
  computed: {
    ...mapGetters({
      updates: "ProductUpdates/getUpdates"
    }),
  },
  mounted: function() {
    this.$store.dispatch("ProductUpdates/setUpdates")
  }
}
</script>
