<template>
  <div class="productUpdateModal" v-if="update">
    <div :class="'modal ' + (active? 'is-active':'') ">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{update.sku}}</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column is-10">
              <h2 v-if="requestedBy">Requested by {{requestedBy}}</h2>
            </div>
            <div class="column">
              <div class="tag is-light is-primary">{{update.status}}</div>
            </div>
          </div>
          <p>{{update.description}}</p>
        </section>
        <footer class="modal-card-foot">
          <button @click="updateStatus" :class="'button ' + (targetAction == 'Completed'? 'is-success': '')">Mark as {{targetAction}}</button>
          <button class="button" @click="close">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ProductUpdates from "@/api/ProductUpdates"
import Users from "@/api/Users"

export default {
  name: 'Product Update Modal',
  methods: {
    close() {
      this.$emit('close')
    },
    updateStatus() {
      ProductUpdates.updateStatus(this.update.uid, this.targetAction.toLowerCase()).then(this.close).catch(error => {
        console.log("Error updating product update status", error)
        this.close()
      })
    },
    setRequestedBy(response) {
      console.log("REQUESTED BY")
      this.requestedBy = response
    },
    getRequestedBy() {
      if(this.update.submitted_by != "Website") {
        Users.getNameByUID(this.update.submitted_by).then(this.setRequestedBy)
      } else {
        this.requestedBy = "Website"
      }
    }
  },
  watch: {
    "update": function() {
      if(this.update && !this.requestedBy) {
        this.getRequestedBy()
      }
    }
  },
  computed: {
    targetAction() {
      var status = this.update.status

      if(status == "pending") {
        return "Completed"
      } else {
        return "Pending"
      }
    }
  },
  props: {
    update: Object,
    active: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
