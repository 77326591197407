<template>
  <div class="variationBuilder">
    <div class="block">
      <h1 class="title">Variation Builder</h1>
      <p class="subtitle">This process replaces all current variations</p>
    </div>

    <div class="attributes" v-if="step == 1">
      <div class="block">
        <small>Step One</small>
        <h2 class="is-size-4"> Let's start with the attributes</h2>
      </div>
      <div class="columns">
        <div class="column is-one-quarter box">
          <div class="field">
            <label>Attribute Name</label>
            <input type="text" class="input" v-model="attributeName">
          </div>
          <div class="field">
            <label>Options</label>
            <input type="text" class="input" v-on:keyup.enter="addOption" v-model="attributeOption">
           
          </div>
           <div class="tags">
              <span class="tag is-success" v-for="(option, i) in attributeOptions">
                {{option}}
                <button class="delete" @click="removeOption(i)"></button>
              </span>
            </div>
          <div class="field">
            <label class="checkbox">
              <input v-model="attributeUsedForVariations" type="checkbox">
              Used for variations
            </label>
          </div>
          <button class="button" @click="addAttribute">Add Attribute</button>
        </div>
        <div class="column">
          <table class="table is-fullwidth">
            <thead>
              <th>Name</th>
              <th>Options</th>
              <th>Used for Variations</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="(attribute, i) in attributes" v-if="attributes.length > 0">
                <td>{{attribute.name}}</td>
                <td>{{attribute.options.join(", ")}}</td>
                <td>{{attribute.variation? 'Yes' : 'No'}}</td>
                <td><a href="#" @click="this.attributes.splice(i,1)" class="has-text-danger">Remove</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <button :disabled="attributes.length == 0" @click="buildVariationsFromAttributes" class="button is-primary">
        Continue
      </button>
    </div>

    <div class="variations" v-if="step == 2">
      <div class="block">
        <small>Step Two</small>
        <h2 class="is-size-4">Add the details for the variations</h2>
        <p class="help">Regular price and SKU for each variation are required to continue</p>

      </div>
      <div class="block">
         <label class="checkbox">
            <input v-model="enableSKUTables" type="checkbox">
            Calculate SKUs
          </label>
      </div>
      <div class="columns is-multiline" v-if="enableSKUTables">
    
        <div class="column is-full">
          <div class="field">
            <label class="label">Base SKU</label>
            <input type="text" class="input" @change="calculateSKUs" v-model="baseSKU">
          </div>
        </div>

        <div class="column is-full box" v-for="(skuTable, i) in skuTables">
          
          <h2 class="label">{{skuTable.name}}</h2>
          <label class="checkbox">
            <input type="checkbox" v-model="skuTable.options[i].useFlatPrice">
            Use Flat Pricing
          </label>
          <table class="table is-fullwidth">
            <thead>
              <th>Option</th>
              <th>Value</th>
            </thead>
            <tbody>
              <tr v-for="(opt, i) in skuTable.options">
                <td>{{opt.name}}</td>
                <td>
                  <input type="text" class="input" v-model="skuTable.options[i].value" @change="calculateSKUs">
                </td>
              </tr>
            </tbody>
          </table>
          <div class="buttons">
              <button class="button is-small" v-if="i > 0" @click="moveTableUp(i)"><i class="fas fa-arrow-up"></i></button>
              <button class="button is-small" v-if="i < skuTables.length" @click="moveTableDown(i)"><i class="fas fa-arrow-down"></i></button>

            </div>
        </div>
      </div>
      <h2>Variations ({{variations.length}})</h2>
      <div>
        <table class="table is-fullwidth">
          <thead>
            <th v-for="attr in attributes">
              {{attr.name}}
            </th>
            <th>
              SKU
            </th>
            <th>
              Regular Price
            </th>
          </thead>
          <tbody>
            <tr v-for="(vari, i) in variations">
             <td v-for="attribute in vari.attributes" :key="attribute.name">
                {{attribute.option}}
              </td>
              <td>
                <input type="text" class="input" v-model="variations[i].sku" v-if="!enableSKUTables">
                <span v-else>
                  {{vari.sku && vari.sku.length > 0? variations[i].sku : "-"}}
                </span>
              </td>
              <td>
                <input type="text" class="input" v-model="variations[i].regular_price">
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="buttons">
        <button class="button" @click="step = 1">Go Back</button>
        <button :disabled="!canCreate" class="button is-primary" @click="createVariations">Create Variations</button>
      </div>
    </div>



  </div>
</template>

<script>
import Products from "@/api/ProductListings"

export default {
  name: 'Variation Builder',
  data() {
    return {
      product: null,
      parentID: null,
      attributes: [],
      baseSKU: "",
      enableSKUTables: false,
      skuTables: [],
      variations: [],
      attributeName: "",
      attributeOptions: [],
      attributeOption: "",
      attributeUsedForVariations: true,
      step: 1
    }
  },
  computed: {
    uid() {
      return this.$route.params.uid
    },
    canCreate() {
      var can = true

      this.variations.forEach(vari => {
        if(vari.sku.length <= 0) {
          can = false
        }
      })

      return can
    }
  },
  methods: {
    removeOption(index) {
      this.attributeOptions.splice(index, 1)
    },
    addOption() {
      this.attributeOptions.push(this.attributeOption.trim())
      this.attributeOption = ""
    },
    async createVariations() {
      this.loading = true
      console.log(this.variations)
      this.$toast.create("Creating variations...")
      const parentAttrResponse = await Products.updateProduct({update: {
        attributes: this.attributes,
        uid: this.uid,
        id: this.parentID
      }})

      console.log(this.parent)
      const deleteResponse = await Products.deleteVariations(this.uid, this.parentID, this.parent.variations)
      const createResponse = await Products.createVariations(this.uid, this.parentID, this.variations)

      this.loading = false

      this.$toast.create("Finished, check console for result details")

      console.log("Operation finished", {
        delete: deleteResponse,
        create: createResponse,
        parentAttrResponse: parentAttrResponse
      })

      this.$router.push("/dashboard/listings/" + this.uid)
    },
    moveTableUp(index) {
      this.moveSKUTable(index, index - 1)
    },
    moveTableDown(index) {
      this.moveSKUTable(index, index + 1)
    },
    moveSKUTable(from, to) {
      this.skuTables.splice(to, 0, this.skuTables.splice(from, 1)[0])
      this.calculateSKUs()
    },
    calculateSKUs() {
      console.log("Calculating SKUs...")
      for(var i = 0; i < this.variations.length; i++) {
        var vari = this.variations[i]
        var sku = this.baseSKU
        
        for(var j = 0; j < this.skuTables.length; j++) {
          var skuTable = this.skuTables[j]
          var tableName = skuTable.name

          //Get corresponding variation value
          var variOption = this.getAttribute(vari, tableName)

          //Get sku element that corresponds to selected value
          var skuEle = this.getSKUforAttribute(skuTable, variOption)

          sku += skuEle
        }

        this.variations[i].sku = sku
      }
    },
    getAttribute(variation, attrName) {
      var attrs = variation.attributes
      for(var i = 0; i < attrs.length; i++) {
        var attr = attrs[i]
        if(attr.name == attrName) {
          return attr.option
        }
      }
    },
    getSKUforAttribute(skuTable, attrValue) {
      var opts = skuTable.options
      for(var i = 0; i < opts.length; i++) {
        var opt = opts[i]

        if(opt.name == attrValue) {
          return opt.value
        }
      }
    },
    buildVariationsFromAttributes() {
      var optionLists = []
      this.attributes.forEach(attr => {
        if(attr.variation) {
          optionLists.push(attr.options)
        }
      })

      console.log("Getting cartesian product of", optionLists)

      var cart = this.cartesian(optionLists)
      var allOptions = []
      cart.forEach(opts => {
        var attrs = []
        opts.forEach((opt, i) => {
          attrs.push({
            name: this.attributes[i].name,
            option: opt
          })
        })

        allOptions.push({
          attributes: attrs,
          regular_price: "",
          sale_price: "",
          sku: ""
        })
      })

      this.variations = allOptions
      this.addSKUTables()
    },

    addSKUTables() {
      this.skuTables = []
      this.attributes.forEach(attr => {
        var table = {}
        table.name = attr.name
        table.options = []

        attr.options.forEach(opt => {
          table.options.push({
            name: opt,
            value: "",
          })
        })

        this.skuTables.push(table)
      })

      console.log("SKU TABLES", this.skuTables)
      this.step = 2

    },
    cartesian(args) {
      var r = [], max = args.length-1;
      function helper(arr, i) {
          for (var j=0, l=args[i].length; j<l; j++) {
              var a = arr.slice(0); // clone arr
              a.push(args[i][j]);
              if (i==max)
                  r.push(a);
              else
                  helper(a, i+1);
          }
      }
      helper([], 0);
      return r;
    },
    addAttribute() {
      this.attributes.push({
        name: this.attributeName,
        options: this.attributeOptions,
        variation: this.attributeUsedForVariations
      })

      this.attributeName = ""
      this.attributeOptions = []
      this.attributeUsedForVariations = true
    },
    setProduct(product) {
      this.parent = product
      this.parentID = product.id
    }
  },
  mounted: function() {
    Products.getByUID(this.uid).then(this.setProduct)
  }
}
</script>
<style scoped>
  tr:hover {
    background-color: #eaeaea;
  }
</style>