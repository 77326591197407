<template>
  <div class="newProductUpdateModal">
    <div :class="'modal ' + (active? 'is-active':'')">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">New Product Update</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label>SKU</label>
            <input type="text" class="input" v-model="sku"/>
          </div>
          <div class="field">
            <label>Description</label>
            <textarea class="textarea" v-model="description" placeholder="Update the price to..."></textarea>
            <small class="has-text-right">{{description.length}}/{{maxDescriptionLength}}</small>
          </div>

        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="submitUpdate" :disabled="!canSubmitUpdate">Submit Update</button>
          <button class="button" @click="close">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ProductUpdates from "@/api/ProductUpdates"

export default {
  name: 'New Product Update Modal',
  props: {
    active: Boolean
  },
  data() {
    return {
      sku: "",
      description: "",
      maxDescriptionLength: 300
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submitUpdate() {
      ProductUpdates.submitUpdate(this.sku, this.description).then(this.close).catch(error => {
        console.log("ERROR SUBMITTING UPDATE", error)
        this.close()
      })
    }
  },
  watch: {
    "sku": function() {
      this.sku = this.sku.toUpperCase()
    },
    "description": function() {
      if(this.description.length > this.maxDescriptionLength) {
        this.description = this.description.substr(0, this.maxDescriptionLength)
      }
    }
  },
  computed: {
    canSubmitUpdate() {
      return this.description.length > 0 && this.sku.length > 0
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
