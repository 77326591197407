class WMTimestamp {
    toDateString(fbTimestamp) {
        var seconds 

        if(fbTimestamp._seconds) {
            seconds = fbTimestamp._seconds
        } else if (fbTimestamp.seconds) {
            seconds = fbTimestamp.seconds
        } else {
            return ""
        }

        var jsDate = new Date(seconds * 1000)
        var months = ["January", "Feburary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        var bigHour = jsDate.getHours()
        var amPM = bigHour > 11? "PM" : "AM"
        var smallHour = ((bigHour + 11) % 12 + 1)
        return months[jsDate.getMonth()] + " " + this.getOrdinalNum(jsDate.getDate()) + ", " + jsDate.getFullYear() + " at " + smallHour + ":" + jsDate.getMinutes() + "" + amPM
        
    }

    getOrdinalNum(n) {
        return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
    }
}

const Timestamp = new WMTimestamp()
export default Timestamp