<template>
  <div class="promotions">
    <div class="columns">
      <div class="column">
        <h1 class="title">Promotions</h1>
      </div>
      <div class="column is-flex is-justify-content-end">
        <router-link to="/dashboard/listings/promotions/new" class="button">Create New</router-link>
      </div>
    </div>
    <table class="table is-fullwidth">
      <thead>
        <th>Name</th>
        <th>Discount</th>
        <th>Products</th>
        <th>Status</th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="promotion in promotions">
          <td>{{promotion.name}}</td>
          <td>{{promotion.discount}}%</td>

          <td>{{promotion.product_count > -1? promotion.product_count : '?'}}</td>
          <td>
            <div class="tag is-warning" v-if="promotion.status == 'Inactive' || promotion.status == 'Waiting For Processing'">{{promotion.status}}</div>
          </td>
          <td>
            <button class="button is-small" @click="processPromotion(promotion)">Process Now</button>
          </td>
        </tr>
      </tbody>
    </table>
    <NewPromotionModal :isActive="newPromotionModalOpen" @close="newPromotionModalOpen = false"></NewPromotionModal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import NewPromotionModal from "@/components/dashboard/promotions/NewPromotionModal"

export default {
  name: 'Promotions',
  data() {
    return {
      newPromotionModalOpen: false
    }
  },
  mounted: function() {
    this.$store.dispatch("Promotions/setPromotions")
  },
  methods: {
    processPromotion(promotion) {
      this.$store.dispatch("Promotions/processPromotion", promotion)
    }
  },
  computed: {
    ...mapGetters({
      promotions: "Promotions/getPromotions"
    })
  },
  components: {
    NewPromotionModal
  }
}
</script>
<style scoped>
  tr {
    cursor: pointer;
  }

  tr:hover {
    background-color: #eeeeee;
  }
</style>
