<template>
  <div class="productList" v-if="lists && lists.length > 0">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Home</router-link></li>
        <li><router-link to="/dashboard/products/lists">Product Lists</router-link></li>
        <li class="is-active"><a href="#" aria-current="page">{{list.title}}</a></li>
      </ul>
    </nav>
    <h1 class="title">{{list.title}}</h1>
    <p>{{list.description}}</p>

    <div class="columns">
      <div class="column">
          <select class="input" v-model="action">
            <option disabled>─ Pricing</option>

            <option value="increase_price">Increase Price</option>
            <option value="decrease_price">Decrease Price</option>
            <option disabled>─ Categories</option>
            <option value="add_category">Add Category</option>
            <option value="remove_category">Remove Category</option>
            <option disabled>─ Tags</option>

            <option value="add_tag">Add Tag</option>
            <option value="remove_tag">Remove Tag</option>
          </select>
      </div>
      <div class="column">
          <select :disabled="loading" class="input" v-if="action == 'add_category' || action == 'remove_category'" v-model="value">
            <option :value="category.id" v-for="category in productCategories" v-html="category.name"></option>
          </select>
          <input type="text" class="input" placeholder="How much?" :disabled="loading" v-if="action == 'increase_price' || action == 'decrease_price'">
      </div>
      <div class="column">
        <button :class="'button ' + (loading? 'is-loading':'')" :disabled="loading" @click="nextOperation">Apply Changes</button>
      </div>
      <div class="column">

      </div>
    </div>  

    <h2>{{list.products.length}} products</h2>
    <ul>
      <li v-for="product in products">
        {{product.name}}
      </li>
    </ul>
  </div>
</template>

<script>
import Products from "@/api/ProductListings"
import {mapGetters} from "vuex"

///////////////
import FB from "@/api/FB"
///////////////

export default {
  name: 'Product List',
  data() {
    return {
      action: "",
      loading: false,
      value: "",
      index: -1,
      products: [],
      operateIndex: -1
    }
  },
  mounted: function() {
    this.$store.dispatch("ProductLists/setLists").then(this.next)
    this.$store.dispatch("ProductListingCategories/setProductCategories")
  },
  methods: {
    getProducts(response) {      
      this.next()
    },
    next() {
      console.log("N E X T")
      this.loading = true
      this.index++

      if(this.index < this.list.products.length) {
        var uid = this.list.products[this.index]
        console.log("Getting " + uid)
        Products.getByUID(uid).then(this.acceptProduct).catch(this.acceptProduct)
      } else {
        this.done()
      }
    },
    done() {
      this.loading = false
    },
    nextOperation(res) {

      if(res) {
        console.log("Operation response for " + this.operateIndex + "/" + this.products.length, res)
      }

      this.loading = true
      this.operateIndex++

      if(this.operateIndex < this.products.length) {
        this.updateProduct()
      } else {
        this.done()
      }
    },
    updateProduct() {
      var action = this.action
      var value = this.value
      var product = this.products[this.operateIndex]

      if(action == "add_category") {
        console.log("Updating categories", [action,value,product])
        var categories = product.categories
        
        if(false && categories.includes(value)) {
          console.log("Product already has requested categories", [action,value,product])

          //Already has requested category
          this.nextOperation()
        } else {
          console.log("Sending request to update categories", [action,value,product])

          value = Number(value)
          categories.push(value)
          Products.updateCategories(product.uid, product.id, categories).then(this.nextOperation).catch(this.nextOperation)
        }
      }
    },
    acceptProduct(response) {
      console.log("Added product", response)
      this.products.push(response)
      this.next()
    },
  },
  computed: {

    uid() {
      return this.$route.params.uid
    },
    ...mapGetters({
      listByUID: "ProductLists/getList",
      lists: "ProductLists/getLists",
      productCategories: "ProductListingCategories/getProductCategories"
    }),
    list() {
      return this.listByUID(this.uid)
    }
  }
}
</script>
<style scoped>
  h1 {
    margin-bottom: 5px !important;
  }
</style>
