<template>
  <div class="ProductEditModal">
    <div :class="'modal ' + (active? 'is-active' : '')">

      <div class="modal-background"></div>
       <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit {{productType}}</p>
          <button class="delete" @click="$parent.modalActive = false" aria-label="close"></button>
        </header>
        <section class="modal-card-body">

          <div class="field">
            <label>Product Title</label>
            <input class="input" :placeholder="product.name" v-model="productTitle" type="text"/>
          </div>
          <div class="field">
            <label>SKU</label>
            <input class="input" :placeholder="product.sku" v-model="sku" type="text"/>
          </div>

          <div class="columns" v-if="product.type == 'simple' || product.type == 'variation'">
            <div class="column">
              <div class="field">
                <label>Regular Price</label>
                <input class="input" :placeholder="product.regular_price" v-model="regularPrice" type="text"/>
              </div>
            </div>
              <div class="column">
              <div class="field">
                <label>Sale Price</label>
                <input class="input" :placeholder="product.sale_price" v-model="salePrice" type="text"/>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="saveProduct">Save changes</button>
          <button class="button" @click="closeModal">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader"
import Products from "@/api/ProductListings"

export default {
  name: 'ProductEditModal',
  components: {
    Loader
  },
  data() {
    return {
      productTitle: "",
      regularPrice: "",
      salePrice: "",
      sku: ""
    }
  },
  methods: {
    saveProduct() {
      var update = {
        id: this.product.id,
        uid: this.product.uid,
      }

      if(this.product.type == "variation") update.parent_id = this.product.parent_id
      if(this.productTitle.length > 0) update.name = this.productTitle
      if(this.sku.length > 0) update.sku = this.sku
      if(this.regularPrice.length > 0) update.regular_price = this.regularPrice
      if(this.salePrice.length > 0) update.sale_price = this.salePrice


      if(this.product.type == "variation") {
        Products.updateVariation(update).then(this.reload).catch(error => {
          console.log("ERROR", error)
        })
      } else {
        Products.updateProducts([update]).then(this.reload).catch(error => {
          console.log("ERROR", error)
        })
      }
      

    },
    reload() {
      this.$router.go()
    },
    closeModal(response) {
      this.productTitle = ""
      this.sku = ""
      this.regularPrice = ""
      this.salePrice = ""
      this.$parent.modalActive = false
    },
  },
  computed: {
    productType: function() {
      if(this.product.type == 'variation') {
        return "Variation"
      }

      return "Product"
    }
  },
  props: {
    active: Boolean,
    product: Object
  }
}
</script>
<style scoped>
  .loaderContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }
</style>
