<template>
  <div class="productLists">
    <h1 class="title">Product Lists</h1>
    <ul>
      <li v-for="list in lists">
        <router-link :to="'/dashboard/products/lists/' + list.uid">{{list.title}}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'Product Lists',
  mounted: function(){
    this.$store.dispatch("ProductLists/setLists")
  },
  computed: {
    ...mapGetters({
      lists: "ProductLists/getLists"
    })
  }
}
</script>
