<template>
  <div class="auditProductModal">
    <div :class="'modal ' + (active? 'is-active':'')">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Audit Product</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          {{product.id}}
          <button @click="start" class="button is-primary">Begin</button>
          <ul>
            {{changes}}
          </ul>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="submitUpdate" :disabled="!productIsDifferent">Update Product</button>
          <button class="button" @click="close">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Products from "@/api/ProductListings"

export default {
  name: 'Product Audit Modal',
  props: {
    active: Boolean,
    product: Object
  },
  data() {
    return {
      wooProduct: {},
      productIsDifferent: false,
      changes: {}
    }
  },
  mounted: function() {

  },
  methods: {
    start() {
      Products.getWebsiteListing(this.product.id).then(this.processResponse).catch((error) => {
        console.log("ERROR", error)
      })
    },
    areArraysEqual(arrOne, arrTwo) {
      if(arrOne.length != arrTwo.length) return false 

      arrOne.forEach(item => {
        if(!arrTwo.includes(item))  {
          return false
        }
      })

      return true
    },
    auditProduct() {
      var wmProduct = this.product
      var wooProduct = this.wooProduct
      var fields = ["attributes", "categories", "description", "images", "name", "regular_price", "sale_price", "sku", "slug", "type", "variations"]

      console.log(wooProduct)

      fields.forEach(field => {
        if(wmProduct[field] && wooProduct[field]) {
          if(wmProduct[field] != wooProduct[field]) {

            if(Array.isArray(wmProduct[field])) {
              if(!this.areArraysEqual(wmProduct[field], wooProduct[field])) {
                this.changes[field] = wooProduct[field]
              }
            } else {
              this.changes[field] = wooProduct[field]
            }
          }
        }
      })

    },
    processResponse(response) {
      this.wooProduct = response
      this.auditProduct()
    },
    close(response) {
      console.log(response)
      this.$emit('close')
    },
    submitUpdate() {
      var changes = this.changes
      changes.uid = this.product.uid
      Products.updateProduct({update: changes}).then(this.close).catch(error => {
        console.log(error)
      })
    }
  },
  computed: {
    productIsDifferent() {
      return true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
