<template>
  <div class="price">
    <span v-if="discount">
      <span>Your Price: <i style="opacity: .5;">{{regularPrice}}</i> {{yourPrice}}</span>
    </span>
    <span v-else-if="!isOnSale">
      <span v-if="regular_price > 0">{{regularPrice}}</span>
      <span v-else>-</span>
    </span>

    <span v-else><i style="opacity: .5;">{{regularPrice}}</i> {{salePrice}}</span>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'Price',
  props: {
    regular_price: String,
    sale_price: String
  },
  methods: {
    formatMoney(num) {
      return "$" + Number(num).toFixed(2).toLocaleString()
    }
  },
  computed: {
    ...mapGetters({
      discount: "PricingProfiles/getCurrentDiscount"
    }),
    yourPrice() {
      return this.formatMoney(Number(this.regular_price) * (1 - (this.discount / 100)))
    },
    isOnSale: function() {
      var saleNum = Number(this.sale_price)
      var regNum = Number(this.regular_price)

      return saleNum < regNum && saleNum > 0
    },
    regularPrice: function() {
      return this.formatMoney(this.regular_price)  
    },
    salePrice: function() {
      return this.formatMoney(this.sale_price)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
