<template>
  <div class="product">
    <div v-if="!loading" style="padding-bottom: 50px;">
      <div class="columns">
        <div class="column">
          <button class="button" @click="goBack">Back</button>
        </div>
        <div class="column buttons" style="display: flex; justify-content: flex-end;">
          <button @click="$router.push('/dashboard/listings/' + product.uid + '/edit')" class="button">
            <span class="icon">
              <i class="fas fa-edit" aria-hidden="true"></i>
            </span>
            <span>Edit</span>
          </button>
          <div class="dropdown is-hoverable is-right">
            <div class="dropdown-trigger">
              <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                <span>Options</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a :href="'https://moscadesign.com/product/' + product.slug" target="_blank" class="dropdown-item">
                  View on Website
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-quarter">
          <figure class="has-shadow image is-square">
            <img :src="product.images && product.images[0]? product.images[0] : ''"/>
          </figure>
        </div>
        <div class="column">
          <h1 class="title" style="margin-bottom: 0px;">{{product.name}}</h1>
          <small v-if="product.last_update">Last updated {{lastUpdated}}</small>

          <h2 class="is-size-4" v-if="product.type == 'simple' | product.type == 'variation'">
            <Price :regular_price="product.regular_price" :sale_price="product.sale_price"></Price>
          </h2>
          <h2 class="is-size-6" v-if="product.sku && product.sku.length > 0">SKU: {{product.sku}}</h2>

          <p v-html="product.description"></p>
          <div v-if="product.type == 'variation' && product.attributes && product.attributes.length > 0">
            <h2>Attributes</h2>
            <ul>
              <li v-for="attribute in product.attributes">
                {{attribute.name}}: {{attribute.option}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr>
      <div class="variations" v-if="hasVariations">
        <h2 class="is-size-5">Variations</h2>
        
        <table class="table is-fullwidth">
          <thead>
            <th>SKU</th>
            <th v-for="attribute in variationAttributes" >
              {{attribute.name}}
            </th>
            <th>Price</th>
          </thead>
          <tbody>
            <tr v-for="(variation, index) in variations">
              <td>{{variation.sku == product.sku? "-" : variation.sku}}</td>
              <td v-for="attribute in variation.attributes">
                {{attribute.option}}
              </td>
              <td>
                <Price :regular_price="variation.regular_price" :sale_price="variation.sale_price"></Price>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ProductEditModal :product="modalProduct" :active="modalActive"></ProductEditModal>
    </div>
    <div v-else style="position: relative; width: 100%; height: 100%;">
      <Loader is-active="true"></Loader>
    </div>
  </div>
</template>

<script>
import Products from "@/api/ProductListings"
import Loader from "@/components/global/Loader"
import ProductEditModal from "@/components/dashboard/productListings/ProductEditModal.vue"
import Price from "@/components/dashboard/productListings/Price"
import Timestamp from "@/api/Timestamp"

export default {
  name: 'Product',
  components: {
    Loader,
    ProductEditModal,
    Price
  },
  data() {
    return {
      product: {},
      loading: true,
      error: false,
      modalActive: false,
      modalProduct: {},
      _uid: ""
    }
  },
  methods: {
    openModal(product) {
      this.modalProduct = product
      this.modalActive = true
    },
    async setProduct(product) {
      console.log("SET PRODUCT", product)
      this.product = product

      if(product.parent_id != 0) {
        const uid = await Products.getUID(product.parent_id)
        this._uid = uid
        Products.getByUID(uid).then(this.setProduct)

      } else {
        var operations = []
        var variations = product.variations

        variations.forEach(variation => {
          variation = variation + ""
          //This should get and upload the variation as well as replace the id with the UID
            operations.push(Products.getVariation(variation))
        })


        Promise.all(operations).then(this.processVariationResponses).catch(this.throwError)
      
      }
 
    },
    goBack() {
      this.$router.back()
    },
    redirect(response) {
        console.log(response)
        this.$router.push("/dashboard/listings/" + response)
        this.loading = false
    },
    processVariationResponses(responses) {
      console.log("VARIATION RESPONSES", responses)
      this.variations = responses
      this.loading = false
    },
    throwError(error) {
      console.log("Error loading product variations", error)
      this.error = true
    }
  },
  computed: {
    uid: function() {
      if(this._uid.length > 0) {
        return this._uid
      }

      return this.$route.params.uid? this.$route.params.uid : false
    },
    hasVariations: function() {
      if(!this.variations) return false
      return this.variations.length > 0 || (this.product.variations && this.product.variations > 0)
    },
    variationAttributes: function() {
      return this.product.attributes.filter(attribute => attribute.variation == true)
    },
    lastUpdated: function() {
      return Timestamp.toDateString(this.product.last_update)
    }
  },
  watch: {
    "$route": function() {

    }
  },
  mounted: function() {
    if(this.uid) {
      Products.getByUID(this.uid).then(this.setProduct)

    }
  }
}
</script>
<style scoped>
  .product {
    height: 100%;
  }

  .is-square img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>