<template>
  <div class="newPromotion">
    <h1 class="title">New Promotion</h1>

    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Name</label>
          <input v-model="name" type="text" class="input">
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Discount (%)</label>
          <input v-model="discount" type="text" class="input">
        </div>
      </div>
    </div>
    <div class="block">
      <h2 class="is-size-5 block">Product Filter</h2>
      <div class="block">
        <label class="label">Include Products</label>

        <div class="columns">
          <div class="column">
            <div class="select">
              <select v-model="currentIncludeField">
                <option :value="field" v-for="field in fields">{{field}}</option>
              </select>
            </div>
          </div>
          <div class="column">
            <div class="select">
              <select v-model="currentIncludeCondition">
                <option :value="condition" v-for="condition in conditions">{{condition}}</option>
              </select>
            </div>
          </div>

          <div class="column">
            <div class="select" v-if="currentIncludeField == 'Category'">
              <select v-model="currentIncludeValue">
                <option :value="category.id + ',' + category.children.join(',')" v-for="category in categories" v-html="category.name"></option>
              </select>
            </div>
            <div class="select" v-if="currentIncludeField == 'Supplier'">
              <select v-model="currentIncludeValue">
                <option v-for="supplier in suppliers" v-html="supplier.name"></option>
              </select>
            </div>
          </div>
        </div>
        
      </div>
      <div class="block">
        <label class="label">Exclude Products</label>

          <div class="columns">
            <div class="column">
              <div class="select">
                <select v-model="currentExcludeField">
                  <option :value="field" v-for="field in fields">{{field}}</option>
                </select>
              </div>
            </div>
            <div class="column">
              <div class="select">
                <select v-model="currentExcludeCondition">
                  <option :value="condition" v-for="condition in conditions">{{condition}}</option>
                </select>
              </div>
            </div>

            <div class="column">
              <div class="select" v-if="currentExcludeField == 'Category'">
                <select v-model="currentExcludeValue">
                  <option :value="category.id + ',' + category.children.join(',')" v-for="category in categories" v-html="category.name"></option>
                </select>
              </div>
              <div class="select" v-if="currentExcludeField == 'Supplier'">
                <select v-model="currentExcludeValue">
                  <option v-for="supplier in suppliers" v-html="supplier.name"></option>
                </select>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block">
      <h2 class="is-size-5 block">Products</h2>
      <label class="label">Add Product</label>
      <ProductSearchBar @onAddProduct="addProduct"></ProductSearchBar>

      <table class="table is-fullwidth">
        <thead>
          <th>Name</th>
          <th>SKU</th>
          <th>List Price</th>
          <th>Sale Price</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="product in products">
            <td>{{product.name}}</td>
            <td>{{product.sku}}</td>
            <td>{{product.regular_price}}</td>
            <td v-if="discount.length > 0">{{Math.ceil(Number(product.regular_price) * discountAmount)}}</td>
            <td v-else>-</td>
            <td></td>

          </tr>
        </tbody>
      </table>
      <div class="is-flex is-justify-content-end">
        <button class="button is-primary" :disabled="canCreate">Create Promotion</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ProductSearchBar from "@/components/global/inputs/ProductSearchBar"

export default {
  name: 'New Promotion',
  data() {
    return {
      name: "",
      discount: "",
      currentIncludeField: "Supplier",
      currentIncludeCondition: "",
      currentIncludeValue: "",
      currentExcludeField: "Supplier",
      currentExcludeCondition: "",
      currentExcludeValue: "",
      include: [],
      exclude: [],
      fields: [
        "Supplier",
        "Category",
      ],
      conditions: [
        "Equals",
        "Does Not Equal"
      ],
      products: [

      ]
    }
  },
  components: {
    ProductSearchBar
  },
  methods: {
    addProduct(product) {
      this.products.push(product)
    },
    addInclude() {
      var data = {
        field: this.currentIncludeField,
        condition: this.currentIncludeCondition == 'Equals'? '==' : '!=',
        value: this.currentIncludeValue
      }

      if(data.field == "Category") {
        var arr = data.value.split(",")
        for(var i = 0; i < arr.length; i++) {
          arr[i] = Number(arr[i])
        }
        data.value = arr
      }

      this.include.push(data)
    },
    addExclude() {
      this.exclude.push({
        field: this.currentExcludeField,
        condition: this.currentExcludeCondition == 'Equals'? '==' : '!=',
        value: this.currentExcludeValue
      })
    },
    createPromotion() {
      this.addInclude()
      this.addExclude()
      this.$store.dispatch("Promotions/createPromotion", {
        name: this.name,
        discount: Number(this.discount),
        include: this.include,
        exclude: this.exclude,
        status: "Waiting For Processing",
        product_count: -1
      }).then(this.onClose)
    }
  },
  computed: {
    canCreate() {
      return !(this.name.length > 0 && this.discount.length > 1 && this.products.length > 1)
    },
    discountAmount() {
      return (100 - Number(this.discount)) / 100
    },
    ...mapGetters({
      suppliers: "ProductSuppliers/getProductSuppliers",
      categories: "ProductListingCategories/getProductCategories"
    })
  },
  mounted: function() {
    this.$store.dispatch("ProductSuppliers/setProductSuppliers")
  }
}
</script>
<style scoped>
  th {
    width: 33.3333%;
  }
  select, .select {
    width: 100%;
  }
</style>