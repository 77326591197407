<template>
    <div>
        <input type="text" class="input productSearchBarInput" placeholder="Search for a product..." v-model="query"/>
        <div  v-on:scroll="onScroll" class="productSearchBarResults has-shadow">
            <transition-group name="list" tag="ul" ref="resultList">
                <li @click="onAdd(result)" v-for="result in results">
                    <h3 class="is-size-5">{{result.name}}</h3>
                    <small>{{getQuickInfo(result)}}</small>
                </li>
                <li v-if="noResults" style="text-align: center;">No Results</li>
            </transition-group>
        </div>
    </div>
</template>
<script>
import {ref} from 'vue'
import algoliasearch from 'algoliasearch/lite';

const client = algoliasearch('1XZQWYJKQ7', '3c3fa1681627fd3f881a256e394cbdae');
const index = client.initIndex('product_listings');

export default {
    data() {
        return {
            query: "",
            awaitingSearch: false,
            totalPages: 0,
            currentPage: 0,
            scrollTop: 0,
            scrollHeight: 1000,
            loading: false,
            totalProducts: 0,
            noResults: false,
            results: [

            ]
        }
    },
    setup() {
        const resultList = ref(null)

        return {
            resultList
        }
    },
    methods: {
        onAdd(product) {
            this.query = ""
            this.results = []
            this.$emit("onAddProduct", product)
        },
        getQuickInfo(result) {
            var message = "SKU: " + result.sku 

            result.attributes.forEach(attr => {
                message += ` | ${attr.name}: ${attr.option}`
            })


            return message
        },
        nextPage() {
            if(this.currentPage < this.totalPages) {
                this.currentPage++
                this.fetchNextPage()
            }

        },
        async fetchResults() {
            if(this.query.length > 3) {
                this.loading = true
                const hits = await index.search(this.query, {
                    hitsPerPage: 15,
                })

                this.currentPage = 0
                this.noResults =  false
                this.loading = false
                this.totalPages = hits.nbPages - 1
                this.totalProducts = hits.nbHits

                if(hits.nbHits <= 0) {
                    this.noResults = true
                }
                console.log(hits)

                this.results = hits.hits
            }
        },
        async fetchNextPage() {
            if(this.query.length > 3) {
                this.loading = true
                const hits = await index.search(this.query, {
                    hitsPerPage: 15,
                    page: this.currentPage
                })
                this.loading = false
                this.totalPages = hits.nbPages

                console.log(hits)

                this.results.push(...hits.hits) 
            }
        },
        onScroll(e) {
            this.scrollTop = Math.ceil(e.target.scrollTop)
            this.scrollHeight = e.target.scrollHeight - e.target.clientHeight
        }
    },

    computed: {
        scrollPos() {
            return this.scrollTop / this.scrollHeight
        }
    },
    watch: {
        scrollPos: function() {
            if(this.scrollPos > .8 && !this.loading) {
                this.nextPage()
            }
        },
        query: function() {
            this.results = []
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.currentPage = 0
                    this.fetchResults()
                    this.awaitingSearch = false
                }, 2000) // 1 sec delay
            }
            this.awaitingSearch = true
        }
    }
}

</script>
<style scoped>
    .productSearchBarInput:focus ~ .productSearchBarResults {
        opacity: 1;
    }

    .productSearchBarResults:focus {
        opacity: 1;
    }

    .productSearchBarResults {
        z-index: 9999999999;
        position: relative;
        border: 1px solid #e2e2e2;
        opacity: 0;
        transition: .25s ease;
        max-height: 225px;
        overflow: auto;
        overflow-x:hidden;
    }

    .productSearchBarResults li {
        padding: 10px;
        cursor: pointer;
    }

    .productSearchBarResults li:hover {
        background-color: #eee;
    }

    /** ANIMATION */
    .list-item {
        display: inline-block;
  
    }
    .list-enter-active, .list-leave-active {
        transition: all .25s;
    }
    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
